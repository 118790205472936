import Header from "./Components/Header/Header";
import Counting from "./Components/Counting/Counting";
import {useState} from "react";
import AddTeam from "./Components/AddTeam/AddTeam";
import WordGetter from "./Components/WordGetter/WordGetter";
import {BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {

    const [teams, setTeams] = useState([])

    const setTeamHandler = (teamName) => {
        const updatedData = [...teams, teamName];
        return setTeams(updatedData)
    }

  return (
   <>
       <Router>
           <Header/>
           <Switch>
               <Route exact path={"/"}>
                   <>
                       <AddTeam onClick={setTeamHandler}/>
                       <Counting teamName={teams}/></>
               </Route>
               <Route path={"/palabras"}>
                   <WordGetter/>
               </Route>
           </Switch>
       </Router>
   </>
  );
}

export default App;
