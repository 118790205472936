import {useState} from "react";
import CountingAdd from "../Counting/CountingAdd";

const AddTeam = props => {

    const [teamName, setTeamName] = useState([]);

    const clickEvent = props.onClick;


    return <>
        <form style={{display:"grid", gridTemplateColumns: 'repeat(auto-fill, calc(50% - 10px))', gap: "20px", marginBlock: "20px", maxWidth: "90%", marginInline: "auto"}}>
        <input style={{borderRadius: '50px', border: 'none', padding: "20px", fontSize: "20px"}} type="text" value={teamName} onChange={ev => setTeamName(ev.target.value)}/>
        <CountingAdd type={"submit"} onClick={(ev) => {
            ev.preventDefault();
            clickEvent(teamName)
            setTeamName('') } }>Agregar equipo</CountingAdd>
        </form>
    </>
}

export default AddTeam;
