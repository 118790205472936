import {useEffect, useState} from "react";
import AddButton from "../Counting/CountingAdd";
import Styles from "./WordGetter.module.css"

const WordGetter = () => {

    const [word,setWord] = useState('');
    const [count, setCount] = useState(0)

    useEffect(()=>{
        const getWord = () => {
            fetch('https://palabras-aleatorias-public-api.herokuapp.com/random')
                .then( el => el.json())
                .then(el => setWord(el.body.Word))
        }

        getWord()
    }, [count])

    return <>
        <div className={Styles.section}>
            <h3>{word}</h3>
            <AddButton onClick={()=>{
                setCount(count + 1);
            }}>Buscar palabra</AddButton>
        </div>
    </>
}

export default WordGetter;
