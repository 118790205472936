import Counter from "./Counter";
import Styles from "./Counting.module.css"

const Counting = props => {

    return <>
        <div className={Styles.grid}>
        {
            props.teamName.length > 0 ? props.teamName.map((team, index) => {
                return <Counter key={index} teamName={team} id={index}/>
            }): "Agregue un equipo para comenzar"
        }
        </div>
    </>
}

export default Counting;
