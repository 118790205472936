import CountingAdd from "./CountingAdd";
import {useState} from "react";
import Styles from "./Counting.module.css"

const Counter = props => {

    const [counter, setCounter] = useState(0);

    const handleAdd = ( ) => {
        setCounter(counter + 1);
    }

    return <>
        <div className={Styles.counterCard} id={props.id}>
            <h2>Equipo {props.teamName}</h2>
            <div>
                <p>El puntaje es</p>
                <h3>{counter}</h3>
                <CountingAdd onClick={handleAdd}>Agregar Punto</CountingAdd>
            </div>
        </div>

    </>

}

export default Counter;
