import Styles from "./Header.module.css";
import image from "./edit.svg";
import {NavLink} from "react-router-dom";

const Header = () => {
    return <>
        <header className={Styles.mainHeader}>
            <div className={Styles.container}>
                <img src={image} alt="Lápiz" className={Styles.image}/>
                <h2 className={Styles.mainTitle}>PictUJV</h2>
            </div>
        </header>
        <div className={Styles.switcher}>
            <NavLink className={Styles.buttons} activeClassName={Styles.activo} exact to={"/"}>Equipos</NavLink>
            <NavLink className={Styles.buttons} activeClassName={Styles.activo} to={"/palabras"}>Palabras</NavLink>
        </div>
    </>
}

export default Header;
