import styled from "styled-components";

const AddButton = styled.button`
  display: block;
  background-color: #003669;
  border-radius: 999px;
  color: #ffffff;
  font-weight: bold;
  transform: scale(1);
  transition: all .3s;
  padding-block: 20px;
  cursor: pointer;
  border: none;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    background-color: #034e94;
    transform: scale(1);
    transition: all .3s;
  }

  &:active {
    background-color: #0f78d7;
    transform: scale(.97);
    transition: all .3s;
  }
`

export default AddButton;
